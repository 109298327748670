@use 'sass:map';
@import '../../../../styles/mixins.scss';

.portClass {
  height: fit-content;

  @media (max-width: 1199px) {
    text-align: center;
    width: auto;
  }

  .title {
    padding-bottom: 16px;

    @media (max-width: 1199px) {
      line-height: normal;
    }
  }
}

.tvl-box {
  height: 100%;

  @media (max-width: 1199px) {
    box-shadow: none;
  }
}
