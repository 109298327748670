@use 'sass:map';
@import '../../../../styles/mixins.scss';

.popover-trigger {
  width: fit-content;
}

.MuiPaper-root.MuiPopover-paper {
  /* background-color: transparent !important; */
  margin-top: 8px;
  outline: 1px solid map.get($text-line, 'light-grey-blue');
  box-shadow: map.get($effect-styles, 'modal');
  border-radius: 8px;
}