@use 'sass:map';
@import '../../../styles/mixins.scss';

.approveButton {
  margin: 16px 0px !important;
  width: auto;

  button {
    height: 60px;
    width: 100%;
    padding: 15px 24px;

    &.disabled {
      color: map.get($text-line, 'dark-grey');
    }
  }

  @media (max-width: 1199px) {
    button {
      font-size: 18px;
      width: 100%;
      height: 51px;
      padding: 15px 16px;
    }
  }

  &.sticky {
    margin: 0 !important;
    padding: 27px 4rem;
    border-top: 1px solid #777e90;
  }

  .btn.btn-df::after {
    padding: 0;
  }
}

.confirmWithdrawDialog .MuiPaper-root {
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 8px;
  overflow: visible;

  @include themify() {
    color: themed('textPrimary');
  }

  z-index: 1;
  padding: 70px 75px 60px 75px;
  width: 680px;
  max-width: 680px;
  color: #fff;
  text-align: center;

  @include themify() {
    background: themed('buttonBorder');
  }

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    cursor: pointer;
    height: calc(100% + -2px);
    width: calc(100% + -2px);
    left: 1px;
    top: 1px;
    border-radius: 8px;

    @include themify() {
      background: themed('backgroundPrimary');
    }
  }

  @media (max-width: 599px) {
    width: 90%;
    margin: 0 auto;
    padding: 40px;
  }

  * {
    margin: 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 599px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    line-height: 22px;
    margin: 30px 0 40px 0;
    font-weight: 500;

    @media (max-width: 599px) {
      font-size: 16px;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }
}