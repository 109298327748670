.number-counter {
    position: relative;
    white-space: nowrap;
    width: fit-content;
    .static {
        position: relative;
        opacity: 0;
        width: fit-content;
    }

    .dynamic {
        position: relative;
        // top: 3px;
        left: 0;
    }
}