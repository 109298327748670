@use 'sass:map';
@import '../../../styles/mixins.scss';

nav {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow: clip;
  max-width: 260px;
}

.MuiPaper-root {
  &.MuiDrawer-paper {
    bottom: 71px !important;
    width: 100%;
    height: auto;
  }
}

.nav-container {
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 24px 16px;
  height: 100%;

  @media (max-width: 1199px) {
    gap: 40px;
    padding: 16px;
  }
}

nav>div .linkClass {
  display: flex;
  align-items: center;
}

.private-beta {
  position: absolute;
  top: 63px;
  right: 51px;
  background: transparent;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  border-radius: 5px;

  @media (max-width: 1199px) {
    top: 45px;
    left: 45px;
    right: auto;
    padding: 4px;
  }
}

.logoClass svg {
  width: 199px;
  height: 45.94px;

  @media (max-width: 1199px) {
    width: 105.24px;
    height: 36px;
  }
}

.connect,
.dropdown {
  padding: 0 1rem;
  margin: 1rem 0;
}

hr {
  width: 100%;
  border: 0;
  border-top: 1px solid;
}

nav .pendingContainer {
  padding: 0.5rem 1rem 1rem;
}

nav .pendingContainer .buttonColor {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .pendingContainer .buttonColor #circularG {
  margin: 0 4px;
}

nav .pendingContainer svg {
  height: 30px;
}

nav .pendingContainer path {
  stroke-width: 5px !important;
}

@media (max-width: 1199px) {
  nav .pendingContainer {
    padding: 0;
  }

  nav .pendingContainer .buttonColor {
    padding: 0.25rem !important;
    font-size: 11px !important;
  }
}

.mobile-nav {
  &:hover {
    background-color: map.get($miscellaneous, 'blue-green') !important;
  }
}

.mobile-menu-bar {
  .navLinks {
    padding: 0px 8px;
    gap: 4px;

    a {
      margin: 0px;
      padding: 8px;
      width: 210px;
    }
  }
}
