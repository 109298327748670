@use 'sass:map';
@import '../../../../styles/mixins.scss';

footer {
  box-sizing: border-box;
  padding: 24px 32px;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-width: 1199px) {
    padding: 40px 32px;
  }

  a {
    text-decoration: none !important;
  }

  .footerSocial {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .footer-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: 1199px) {
      gap: 20px;
      margin-top: 20px;
    }
  }

  .footerOfficial {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
        color: #ffffff;
      }
    }
  }

  .footerLink {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (max-width: 1199px) {
      gap: 20px;
    }

    & > div {
      width: max-content;
    }
  }

  .footerLinkWithIcon {
    a {
      display: block;
      font-weight: 600;
      margin-bottom: 1rem;

      &:hover {
        transition: 0.25s;
        color: map.get($miscellaneous, 'bright-blue');

        span {
          color: map.get($miscellaneous, 'bright-blue');
        }

        img {
          filter: invert(43%) sepia(72%) saturate(2355%) hue-rotate(230deg)
            brightness(99%) contrast(107%);
        }
      }
    }

    svg {
      width: 20px;
      margin: 0 8px -2px 0;
    }

    img {
      width: 16px;
      margin: 0 9px -3px 2px;
      filter: invert(100%) sepia(0%) saturate(7469%) hue-rotate(147deg)
        brightness(99%) contrast(110%);
    }
  }

  .textSecondary {
    text-transform: capitalize;
    width: max-content;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .auditBtn {
    padding: 4px 8px;
    background: map.get($gradient, 'standard');
    border-radius: 4px;
    margin-top: 1em;
    border: none;

    div {
      color: map.get($text-line, 'light-black');
    }

    &:hover {
      background: map.get($gradient, 'hover-dark');
      box-shadow: map.get($effect-styles, 'dark');
      border-radius: 8px;
    }
  }
}
