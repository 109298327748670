.circularGContainer {
  position: relative;
  width: 30px !important;
  height: 30px !important;
  margin: 0px 8px;
  border-radius: 50%;
}

.circularG {
  position: absolute;
  background-color: white;
  width: 9px;
  height: 9px !important;
  border-radius: 50%;
  transform: scale(.475);
  animation-name: bounce_circularG;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.circularG_1 {
  left: 0;
  top: 11px;
  animation-delay: 0.41s;
}

.circularG_2 {
  left: 3px;
  top: 3px;
  animation-delay: 0.55s;
}

.circularG_3 {
  top: 0;
  left: 11px;
  animation-delay: 0.69s;
}

.circularG_4 {
  right: 3px;
  top: 3px;
  animation-delay: 0.83s;
}

.circularG_5 {
  right: 0;
  top: 11px;
  animation-delay: 0.97s;
}

.circularG_6 {
  right: 3px;
  bottom: 3px;
  animation-delay: 1.1s;
}

.circularG_7 {
  left: 11px;
  bottom: 0;
  animation-delay: 1.24s;
}

.circularG_8 {
  left: 3px;
  bottom: 3px;
  animation-delay: 1.38s;
}

@keyframes bounce_circularG {
  0% {
    transform: scale(.85);
  }

  100% {
    transform: scale(.475);
  }
}


@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.confirmation-spinner {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
