@use 'sass:map';
@import '../../../styles/mixins.scss';

.tableAssets {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 500;

  .negativeYield {
    color: #ff6a6a;
  }

  .positiveYield {
    color: #81ff8d;
  }

  &:hover {
    background-color: #4e4e4e;
    border-radius: 8px;
  }

  .investButton {
    button {
      text-transform: capitalize;
      color: white;
      background-color: #757575;
      padding: 0.5rem 0;
      box-shadow: none;
      border: none;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: #979797;
      }

      @media (max-width: 1199px) {
        font-size: 12px;
        min-width: auto;
        padding: 0.5rem;
      }
    }
  }
}

.expanded {
  background-color: #4e4e4e;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.reminder-container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8% 24px;
  gap: 32px;
  color: map.get($text-line, 'light-grey');
  border-radius: 0px 0px 8px 8px;

  @media (max-width: 1199px) {
    height: 166px;
    margin: 16px 16px 0px;
    padding: 8% 16px;
    border-radius: 8px !important;
    background: map.get($miscellaneous, 'strategy-hover');
  }
}

.position-container {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 1199px) {
    padding-top: 0px !important;
    padding: 16px !important;
    gap: 16px;
  }
}