@use 'sass:map';
@import '../../../../../styles/mixins.scss';

.selectedButtonList {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0px;
  top: 0px;
  gap: 10px;

  &.small {
    position: static;
    gap: 24px;
    justify-content: center !important;
    margin-bottom: 12px;
  }

  .selectebutton {
    padding: 4px 16px;
    border-radius: 8px;

    &.small {
      padding: 1.5px 6px;
      border-radius: 6px;
    }

    @media (hover: hover) {
      &:hover {
        transition: 0.5s;
        box-shadow: map.get($effect-styles, 'light');
      }
    }

    cursor: pointer;
  }
}
