@use 'sass:map';
@import '../../../../styles/mixins.scss';

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12px;

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-left: 2px;
    margin-top: 2px;
  }
}