@use 'sass:map';
@import '../../../../../../styles/mixins.scss';

.backtest-anlysis {
  .subtitle-1 {
    margin-top: 16px;
  }

  .date-title {
    color: map.get($text-line, 'grey');
    height: 24px;
    padding: 0px 0px 12px;
  }

  .grid2-mini {
    margin: 16px 0px;
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 8px;
    padding: 8px 16px;

    .subtitle-2 {
      color: #b7b7b7;
    }
  }
}
