@use 'sass:map';
@import '../../../styles/mixins.scss';

.overviewClass {
  padding: 0px 24px 26px 24px;
  border-radius: 8px 8px 0px 0px;
  margin: auto;

  @media (max-width: 1199px) {
    border-radius: 10px;
    padding: 16px;
  }

  .title {
    padding-top: 32px;

    @media (max-width: 1199px) {
      padding-top: 0px;
    }
  }

  .subpageBtn {
    background-color: #757575;
    padding: 0.01rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;

    &.active {
      background: #969696;
    }
  }

  .portfolio {
    padding-top: 1rem;
    font-weight: 700;
    font-size: 24px;
  }

  .view-history {
    float: right;
    padding-left: 0.5rem;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .header {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  .totalContainer {
    // width: 50%;
    // justify-content: space-between;
    padding-top: 32px;
    gap: 48px;

    @media (max-width: 1670px) {
      width: 100%;
      justify-content: start;
    }

    @media (max-width: 1199px) {
      padding-top: 16px;
      flex-direction: column;
      width: 100%;
      gap: 8px;

      div:nth-child(2) {
        margin-top: 8px;
      }
    }

    .item-block {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media (max-width: 1199px) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      }
    }

    p {
      margin: 0;
    }
  }
}

.section-groups {
  display: flex;
  height: 40px;
  gap: 1rem;
  padding: 2rem 0px 0.5rem 0px;
  align-items: center;

  @media (max-width: 1199px) {
    padding: 16px 0px;
  }

  .section-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    background: #757575;
    border-radius: 8px;

    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    &.active {
      font-weight: 600;
      background: #969696;
    }

    @media (max-width: 1199px) {
      line-height: 21px;
      padding: 8px 12px;
      background: #757575;

      &.active {
        background: #2a2a2a;
      }
    }
  }
}
