@use 'sass:map';
@import '../../../styles/mixins.scss';

.token {
  position: relative;
  align-items: flex-start;
  padding: 0;
  flex-wrap: wrap;

  &.errorColor {
    input {
      @include themify() {
        color: themed('textPrimary') !important;
      }
    }

    .dangerText {
      @include themify() {
        color: themed('errorColor') !important;
      }
    }

    .alertText {
      font-size: 11px !important;

      @include themify() {
        color: themed('errorColor') !important;
      }

      margin: 0;
      position: absolute;
      bottom: 6px;
    }
  }

  .selectToken {
    height: 100%;

    .flex {
      align-items: flex-start;
      justify-content: space-between;

      &:last-of-type {
        margin-top: 0.5rem;
      }
    }

    p {
      margin: 0;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      text-align: left;
    }

    @media (max-width: 1199px) {
      width: auto;
      border-right: 0;
      padding: 0;
      padding-top: 10px;
    }
  }

  .selectValue {
    height: 32px;
    padding: 14.5px 16px;
    margin: 0.75rem 0rem;
    margin-left: 10px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    flex: 1;

    span {
      &.light-grey {
        color: map.get($text-line, 'light-grey');
      }
    }

    .MuiInputBase-root {
      align-items: center;
      bottom: 0;
      top: 0;

      .Mui-disabled {
        text-fill-color: white !important;

        @include themify() {
          -webkit-text-fill-color: themed('textPrimary') !important;
        }
      }

      @media (max-width: 1199px) {
        max-width: calc(100% - 123px);
        z-index: 1;
      }

      input {
        padding: 0;
        text-align: left;
        margin: 0;
        font-size: 24px;
        font-weight: 500;

        @media (max-width: 1199px) {
          font-size: 16px;
        }
      }
    }

    &.withdraw {
      padding: 1rem 1.5rem 1.5rem 1.5rem;

      @media (max-width: 1199px) {
        flex-direction: row;
        justify-content: flex-start;
      }

      img {
        height: 20px;
        width: 16px;
        margin-right: 1rem;
        position: relative;

        @media (max-width: 1200px) {
          margin: auto 20px auto 0;
        }
      }

      p {
        position: absolute;
        font-size: 11px;
        bottom: 8px;
        color: #969cab;
      }

      @media (max-width: 1199px) {
        p {
          font-size: 9px;
          line-height: 10px;
        }
      }
    }

    .setMax {
      float: right;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      height: 100%;
      right: 12px;
      margin: auto;

      span {
        margin-top: 0.25rem;

        @include themify() {
          color: themed('textSecondary');
        }
      }

      @media (max-width: 1199px) {
        top: 25px;
        height: fit-content;
        margin: auto 0;
        align-items: flex-start;
      }
    }

    .textUpper {
      font-size: 14px;
      text-transform: uppercase !important;
      margin: 0;

      @media (max-width: 1199px) {
        margin-top: 12px;
      }
    }

    .btn.btn-df::after {
      padding: 0;
    }

    button {
      padding: 0.5rem 0.66rem;
      cursor: pointer;

      @media (max-width: 1199px) {
        margin-top: 10px;
      }
    }

    .flex {
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: 10px;

      p {
        @include themify() {
          color: themed('textPrimary');
        }

        font-size: 14px;
        font-weight: 500;
      }

      span {
        margin: 0.75rem 0;
        font-size: 12px;

        @include themify() {
          color: themed('textSecondary');
        }
      }
    }

    @media (max-width: 1199px) {
      border-left: 0;
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      flex-basis: auto;
      margin: 10px 0;
    }
  }

  @media (max-width: 1199px) {
    display: block;
  }
}

.token-popover {
  .MuiPaper-root {
    width: 552px;

    @include themify() {
      background-color: themed('backgroundSecondary');
    }

    border: 1px solid;
    border-radius: 8px;
    position: relative;
    transform: translate(-2px, -1px) !important;

    @include themify() {
      color: themed('textPrimary');
    }

    &>div {
      position: relative;
      padding: 0.75rem 1rem;

      .flex {
        justify-content: space-between;
        margin-bottom: 1rem;

        div {
          @include themify() {
            color: themed('textSecondary');
          }

          cursor: pointer;
        }

        p {
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}
