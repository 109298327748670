@use 'sass:map';
@import '../../../../styles/mixins.scss';

.MuiDrawer-paper {
  &.MuiDrawer-paperAnchorBottom {
    border-radius: 24px 24px 0px 0px;
    bottom: 71px;
  }
}

.cross {
  position: absolute;
  top: 25px;
  right: 21px;
  width: fit-content;
  cursor: pointer;
  z-index: 1;

  svg {
    width: 14px !important;
    height: 14px !important;
  }
}

.hover {
  cursor: pointer;
}
