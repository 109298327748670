@use 'sass:map';
@import '../../../styles/mixins.scss';

span.MuiSlider-markLabel {
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  transform: translateX(-30%);

  &[data-index='0'] {
    left: 1% !important;
  }

  &[data-index='4'] {
    left: 96% !important;
  }
}

.withdrawal-slider {
  position: relative;
  align-items: flex-start;
  padding: 0;
  flex-wrap: wrap;

  .selectToken {
    height: 100%;
    padding: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1rem;

    .flex {
      align-items: flex-start;
      justify-content: space-between;

      &:last-of-type {
        margin-top: 0.5rem;
      }
    }

    p {
      margin: 0;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      text-transform: uppercase;
      text-align: left;
    }

    @media (max-width: 1199px) {
      width: auto;
      border-right: 0;
    }
  }

  .selectValue {
    box-sizing: border-box;
    height: 100%;
    padding: 12px 0px;
    width: 75px;
    height: 42px;
    text-align: center;
    margin: auto;
    border-radius: 10px;

    @media (max-width: 1199px) {
      border-left: 0;
      display: flex;
      flex-direction: row;
      width: 99px;
      height: 36px;
      padding: 6px 16px;
      position: absolute;
      top: 73%;
      right: 24px;
    }

    span {
      &.light-grey {
        color: map.get($text-line, 'light-grey');
      }
    }

    .MuiInputBase-root {
      bottom: 0;
      top: 0;

      .Mui-disabled {
        text-fill-color: white !important;

        @include themify() {
          -webkit-text-fill-color: themed('textPrimary') !important;
        }
      }

      @media (max-width: 1199px) {
        max-width: 100%;
      }

      input {
        padding: 0;
        text-align: left;
        margin: 0;
        font-size: 24px;
        font-weight: 500;

        @include themify() {
          color: themed('textPrimary');
        }
      }
    }

    &.withdraw {
      padding: 1rem 1.5rem 1.5rem 1.5rem;

      @media (max-width: 1199px) {
        flex-direction: row;
        justify-content: flex-start;
      }

      img {
        height: 20px;
        width: 16px;
        margin-right: 1rem;
        position: relative;

        @media (max-width: 1200px) {
          margin: auto 20px auto 0;
        }
      }

      p {
        position: absolute;
        font-size: 11px;
        bottom: 8px;
        color: #969cab;
      }

      @media (max-width: 1199px) {
        p {
          font-size: 9px;
          line-height: 10px;
        }
      }
    }

    .setMax {
      float: right;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      height: 100%;
      right: 12px;
      margin: auto;

      span {
        margin-top: 0.25rem;

        @include themify() {
          color: themed('textSecondary');
        }
      }

      @media (max-width: 1199px) {
        top: 25px;
        height: fit-content;
        margin: auto 0;
        align-items: flex-start;
      }
    }

    .textUpper {
      font-size: 14px;
      text-transform: uppercase !important;
      margin: 0;

      @media (max-width: 1199px) {
        margin-left: 0;
      }
    }

    .btn.btn-df::after {
      padding: 0;
    }

    button {
      padding: 0.5rem 0.66rem;
      cursor: pointer;
    }

    .flex {
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: 10px;

      p {
        @include themify() {
          color: themed('textPrimary');
        }

        font-size: 14px;
        font-weight: 500;
      }

      span {
        margin: 0.75rem 0;
        font-size: 12px;

        @include themify() {
          color: themed('textSecondary');
        }
      }
    }
  }

  @media (max-width: 1199px) {
    display: block;
  }

  .slider-set-percentage {
    width: 80%;

    @media (max-width: 1199px) {
      width: 100%;
    }
  }

  .estimated-amount {
    justify-content: space-between;
    div > p {
      margin-block-start: 0px;
      margin-block-end: 0px;
    }

    @media (max-width: 1199px) {
      flex-direction: column;
      gap: 8px;
      max-width: calc(100% - 110px);
    }
  }
}

.token-popover {
  .MuiPaper-root {
    width: 552px;

    @include themify() {
      background-color: themed('backgroundSecondary');
    }

    border: 1px solid;
    border-radius: 8px;
    position: relative;
    transform: translate(-2px, -1px) !important;

    @include themify() {
      color: themed('textPrimary');
    }

    & > div {
      position: relative;
      padding: 0.75rem 1rem;

      .flex {
        justify-content: space-between;
        margin-bottom: 1rem;

        div {
          @include themify() {
            color: themed('textSecondary');
          }

          cursor: pointer;
        }

        p {
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}
