@use 'sass:map';
@import '../../../../styles/mixins.scss';

.connectBody {
  box-sizing: border-box;
  padding: 32px 24px 40px;
  width: 377px;
}

.chain-list {
  display: flex;
  gap: 16px;
  flex-direction: column;

  .btn-outline::before {
    background-color: #374d56;
  }

  .btn.btn-df::after {
    padding: 0px;
  }
}

.wallet-list {
  display: flex;
  gap: 16px;
  flex-direction: column;

  .btn.btn-df::after {
    padding: 0px;
  }
}