@use 'sass:map';
@import '../../../../styles/mixins.scss';

.navLinks {
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  gap: 12px;

  a {
    box-sizing: border-box;
    padding: 10px 16px;
    width: 228px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 8px;
    margin: 0 auto;

    p {
      margin: 0;
      color: map.get($text-line, 'light-grey');
    }

    &:not(.linkDisabled):hover {
      background: map.get($miscellaneous, 'dark-transparent-background');
      border-radius: 8px;

      p {
        color: map.get($text-line, 'white');
      }

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .active {
    background: map.get($miscellaneous, 'strategy-hover');
    border-radius: 8px;

    p {
      font-weight: 700;
      color: map.get($text-line, 'white');
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
}

@media (max-width: 1199px) {
  .navLinks .active p {
    font-weight: 600;
  }
}

.linkDisabled {
  cursor: auto !important;
  pointer-events: none;
}

.navDisabled {
  position: relative;
  border: none;
  overflow: visible;
  border-radius: 6px;
  margin: 0 10px;
  background-color: #2c4f55;

  &>div {
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    background: map.get($gradient, 'standard');
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-transform: initial;
    padding: 4px 8px;
  }

  &:before {
    position: absolute;
    content: '';
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    z-index: -1;
    left: -1px;
    top: -1px;
    border-radius: 6px;
    background: map.get($gradient, 'icon') !important;
  }
}
