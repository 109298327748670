@use 'sass:map';
@import '.,/../../../../../styles/mixins.scss';

.settingModal {
  box-sizing: border-box;
  box-shadow: map.get($effect-styles, 'dark');
  width: 435px;
  max-width: 435px;
  max-height: calc(90vh - 216px);
  overflow-y: auto;
  overflow-x: hidden;

  // min-height: 484px;
  text-align: left;
  border-radius: 8px;
  position: relative;

  padding: 24px;
  margin: 1px 10px;

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: auto;
  }

  .settingContainer {
    max-height: 90vh;

    .info-grid {
      padding: 8px;
      gap: 4px;
      border-radius: 10px;

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }

    .fee-grid {
      padding: 12px 16px;
      background: map.get($modal, 'dark-transparent') !important;
      border-radius: 10px;
    }

    .value-setting {
      padding: 20px 0px;
      gap: 12px;

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
      }

      .input-grid {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .number-input {
          width: calc(100% - 100px);

          @media (max-width: 1199px) {
            width: calc(100% - 87px);
          }

          &.errorColor {
            input {
              outline: none;
              background: linear-gradient(#59727d, #58707a) padding-box,
                map.get($warning, 'warning') border-box;
              border-radius: 10px;
              border: double 1px transparent;
            }
          }

          button {
            right: 0px;
            height: 42px;
          }
        }
      }

      .error-view {
        display: flex;
        align-items: center;
        padding-top: 15px;
        background: map.get($warning, 'warning');
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  h5 {
    &.title {
      text-transform: uppercase;

      @include themify() {
        color: themed('textPrimary');
      }

      padding: 0 0 20px;
      margin: 0;
      text-align: left;

      @media (max-width: 1199px) {
        padding: 0.5rem 0;
        margin: 0 !important;
        font-size: 16px !important;
      }
    }
  }

  .MuiInputBase-root {
    padding-left: 1px;
    font-family: 'Poppins';
    width: 100%;
    height: 42px;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 42px;

    border-radius: 10px;
    text-align: right;
    padding-right: 16px;
  }
}

.pop-transaction-setting {
  .MuiPaper-root.MuiPopover-paper {
    background: transparent !important;
    outline: none;
    box-shadow: none;
    margin: 0px;
    overflow-y: hidden;
  }
}
