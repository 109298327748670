.topbar {
  padding: 16px;
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1199px) {
    margin-top: 0px;
    margin-bottom: 12px;
    position: relative;
    height: 36px;
  }

  .topbar-right {
    position: absolute;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 1199px) {
      display: flex;
      flex-direction: row;
      padding-right: 6px;
      position: relative;
      right: 0px;
      height: 68px;
    }
  }
}
