@import '../../../styles/mixins.scss';

.strategy {
  height: 45px;
  font-weight: 500;
  .assetCont {
    display: flex;
    @include themify() {
      color: themed('textPrimary');
    }
    text-transform: initial;
    font-size: 14px;
    height: 35px;
    font-weight: 500;
    & > div {
      & > div {
        display: flex;
      }
    }
    .imgContainer {
      display: flex;
      margin-top: -3px;
      margin-left: 0rem;
      margin-right: .5rem;
    }
    .chainClass {
      text-transform: capitalize;
      color: #777E90;
      margin: 0;
      font-weight: 500;
      font-size: 12px;
    }
    p {
      margin: 0;
      font-size: 18px;
      line-height: 18px;
    }
    .fontOrange {
      border-radius: 4px;
      font-size: 12px;
      border: 1px solid;
      border-color: #FF8D44!important;
      padding: 0 .25rem;
      margin: auto .5rem;
      height: max-content;
      text-transform: capitalize;
    }
  }
  @media (max-width: 1199px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
}