#connectDialog .MuiPaper-root {
  background-color: #2d2b40;
  position: relative;
  overflow: visible;
}

#connectDialog .MuiPaper-root:before {
  position: absolute;
  content: '';
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  z-index: -1;
  left: -1px;
  top: -1px;
  border-radius: 4px;
  background: linear-gradient(270deg, #32c5ff 0%, #672cff 100%) !important;
}

#connectDialog .connectTitle {
  font-size: 24px;
  line-height: 36px;
  border-bottom: 1px solid;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
}

#connectDialog .connectTitle p {
  margin: 0 auto;
}

#connectDialog .connectBody {
  padding: 1.5rem 0 20px;
}

#connectDialog .connectContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  #connectDialog .connectContainer {
    width: max-content;
  }
}

.connectClose {
  float: right;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 200;
}

.connect-wallet {
  padding: 8px 16px;
  margin-right: 0px;
  height: 40px;

  @media (max-width: 1199px) {
    padding: 8px 10px;
    margin-right: 8px;
    height: 36px;
  }

  &.btn.btn-sm::after {
    padding: 0px;
  }
}