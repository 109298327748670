@use 'sass:map';
@import '../../../../styles/mixins.scss';

.connectCard {
  width: 329px;
  height: 51px;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: 0px;
  padding: 12px 0px;
  padding-left: 72px;

  &:hover {
    img {
      filter: drop-shadow(map.get($effect-styles, 'light'));
    }
  }

  .chain-info {
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 24px;
  }
}

.connectCard-selected {
  .chain-info {
    img {
      filter: drop-shadow(map.get($effect-styles, 'light'));
    }
  }
}

.disabled {
  .chain-info {
    span {
      color: map.get($text-line, 'dark-grey');
    }
  }
}

.comingSoon {
  position: relative;
  border: none;
  overflow: visible;
  border-radius: 6px;
  margin: 0 10px;
  text-transform: none;

  &>div {
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    padding: 4px 8px;
  }

  &:before {
    position: absolute;
    content: '';
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    z-index: -1;
    left: -1px;
    top: -1px;
    border-radius: 6px;
  }
}