body {
  font-family: Poppins !important;
  background-color: #2d2b40;
}

.linkClass {
  color: #FFFFFF;
  text-decoration: none;
}

.button {
  text-transform: capitalize;
  background: #672CFF;
  transition: .15s;
  color: #FFFFFF;
  height: max-content;
  font-weight: 600;
  border-radius: 4px;
  font-size: 12px;
  padding: .25rem .5rem;
  line-height: 20px;
  position: relative;
  margin: auto;
  border: 0;
  transition: .15s;
  cursor: pointer;
}

.buttonColor {
  text-transform: capitalize;
  border-radius: 8px;
  width: 100%;
  max-width: 210px;
  padding: .75rem 1rem;
  background: linear-gradient(270deg, #78CCFF 0%, #672CFF 100%);
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 550;
  border: 0 !important;
  transition: .25s;
}

.buttonColor:hover {
  transition: .25s;
  background: linear-gradient(270deg, #2BB0E5 0%, #5A25E1 100%);
}

@media (max-width: 1199px) {
  .buttonColor {
    max-width: 100%;
  }
}
