@use 'sass:map';
@import '../../../../../../styles/mixins.scss';

.pdn-strategy-details {
  border-radius: 8px;
  width: 1180px;
  @media (max-height: 834px) {
    height: 93.5vh;
  }
  @media (min-height: 834px) and (max-height: 896px) {
    height: 780px;
  }
  @media (min-height: 896px) {
    height: 87vh;
  }
}

.pdn-strategy-details-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  .details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .action-button-group {
      margin-top: 17px;

      button {
        width: 160px;
        height: 43px;
        padding: 8px 16px;
      }

      .action-withdraw {
        margin-left: 16px !important;
      }
    }
  }
}

.pdn-strategy-details-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  overflow-y: scroll;
  @media (max-height: 834px) {
    height: calc(93.5vh - 197px);
  }
  @media (min-height: 834px) and (max-height: 896px) {
    height: 583px;
  }
  @media (min-height: 896px) {
    height: calc(87vh - 197px);
  }
}

.modal-pdn-strategy-details {
  outline: 2px solid map.get($text-line, 'light-grey-blue');
  @media (max-height: 834px) {
    top: calc(53.5vh);
    height: 93.5vh;
  }
  @media (min-height: 834px) and (max-height: 896px) {
    top: calc(6.5% + 390px);
    height: 780px;
  }
  @media (min-height: 896px) {
    height: 87vh;
  }
}
