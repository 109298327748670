@use 'sass:map';
@import '../../../../styles/mixins.scss';

// Modal
.MuiModal-root {
  .text-center {
    text-align: center;
  }

  .strategyContainer {
    top: calc(100% - 404px);
  }

  .MuiBox-root {
    box-sizing: border-box;
    padding: 64px 32px 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 547px;
    box-shadow: map.get($effect-styles, 'modal');
    border-radius: 8px;

    @media only screen and (max-width: 1199px) {
      padding: 24px;
    }
  }
}

.transaction-pop-over {
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 24px 24px 0px 0px;
    padding: 24px;
    gap: 24px;
    width: auto;

    svg {
      width: 60px;
      height: 60px;
    }
  }
}

.modal-confirmation {
  .MuiBox-root {
    width: 480px;
  }
}