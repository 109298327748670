@use 'sass:map';
@import '../../../styles/mixins.scss';

.disclaimer {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  cursor: auto;
  align-items: center;
  justify-content: center;
  background-color: map.get($modal, 'dark-background');
  text-align: center;
  overflow-y: scroll;
  outline: 0;

  @media (max-width: 1199px) {
    align-items: flex-end;
    position: relative;
    bottom: 73px;
  }

  .wrapper {
    box-sizing: border-box;
    border: none;
    width: 450px;
    height: 690px;
    outline: 2px solid map.get($text-line, 'light-grey-blue');
    box-shadow: map.get($effect-styles, 'modal');
    padding: 32px 24px;
    gap: 16px;

    position: absolute;
    top: 11.45%; // 115/1004

    @media (max-width: 1199px) {
      width: 358px;
      height: auto;
      max-height: 574px;
      padding: 24px 16px;
    }

    @media (max-width: 389px) {
      width: 95%;
      height: auto;
      max-height: 574px;
      padding: 24px 16px;
    }
  }

  .title {
    margin-bottom: 16px;

    @include themify() {
      color: themed('textPrimary');
    }
  }

  .text {
    text-align: left;
    max-height: 452px;
    overflow-y: auto;

    p {
      margin: 0px;
      padding-right: 8px;

      a {
        text-decoration: underline;
      }
    }

    @media (max-width: 1199px) {
      max-height: 369px;

      p {
        padding-right: 6px;
      }
    }
  }

  .MuiCheckbox-root {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    padding: 0px 10px 0px 0px;
    color: map.get($text-line, 'light-grey-blue');
    align-items: flex-start;
    box-sizing: content-box;

    @media (max-width: 1199px) {
      width: 16px;
      height: 16px;
      padding: 0px 8px 0px 0px;
    }

    svg {
      width: 18px;
      height: 18px;

      @media (max-width: 1199px) {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      svg {
        background: rgba(105, 131, 142, 0.75);
        border-radius: 3px;
      }
    }
  }

  .flex {
    cursor: pointer;
    justify-content: left;
    margin: auto;
    margin-top: 16px;
  }

  .acknowledge {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    @media (max-width: 1199px) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .disclaimer-confirm {
    button {
      margin-top: 16px;
      width: 100%;
    }
  }
}