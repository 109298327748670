/*  Light 300, Regular 400, Medium 500, SemiBold 600, Bold 700*/
html {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semiBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

h1,
.headline-1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
}

h2,
.headline-2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

h3,
.headline-3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}

h4,
.headline-4 {
  font-size: 18px;
  line-height: 18px;
}

h5,
.headline-5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25;
}

.subtitle-1 {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  /* Paragraph Spacing: 4 */
}

.subtitle-3 {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.body-2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.button-1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.button-2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.button-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.button-4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.button-5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.button-6 {
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

@media only screen and (max-width: 1199px) {
  .headline-1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }

  .headline-2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }

  .headline-3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
  }

  .headline-4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .headline-5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  .subtitle-1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }

  .subtitle-2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  .subtitle-3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    /* Paragraph Spacing: 6 */
  }

  .subtitle-4 {
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
  }

  .body-1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .body-2 {
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    /* Paragraph Spacing: 2 */
  }

  .button-1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }

  .button-2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .button-3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .button-4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-decoration: underline;
  }

  .button-5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
