@use 'sass:map';
@import '../../../../styles/mixins.scss';

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  outline: 1.5px solid map.get($text-line, 'light-grey-blue');

  box-shadow: map.get($effect-styles, 'modal');
  border-radius: 8px;

  .cross {
    position: absolute;
    top: 32px;
    right: 30px;
    width: fit-content;
    cursor: pointer;
  }

  .hover {
    cursor: pointer;
    height: 100%;
  }
}
