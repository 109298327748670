@use 'sass:map';
@import '../../../../styles/mixins.scss';

.fix-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;
}

.MuiBottomNavigation-root {
  height: 71px !important;
}

.MuiBottomNavigationAction-root:hover {
  color: white;
  background: map.get($miscellaneous, 'dark-transparent-background') !important;
}

.MuiBottomNavigationAction-label {
  text-transform: capitalize;
  color: white;
  font-family: Poppins !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px;
  margin-top: 10px;
}

.Mui-disabled {
  background: linear-gradient(180deg, #31585e 0%, #253e46 96.09%) !important;
}