@use 'sass:map';
@import '../../../../styles/mixins.scss';

.authUser-trigger {
  font-size: 12px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
}

.authUser-address-block {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &:hover {
    outline: 1px solid map.get($text-line, 'light-grey-blue');
  }

  @media (max-width: 1199px) {
    padding: 6px;
  }
}

[aria-describedby='simple-popover']>div>.authUser-address-block {
  box-sizing: border-box;
  outline: 1px solid map.get($text-line, 'light-grey-blue');
}

.headline-5.top {
  position: relative;
  margin-left: 11px;
  margin-right: 11px;
}

.authUser-content {
  border-radius: 8px;

  @media (max-width: 1199px) {
    border-radius: 0px;
  }
}

.desktop {
  width: 395px;
}

.authUser-content-top {
  padding: 24px;
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1199px) {
    margin-top: 24px;
  }
}

.authUser-content-bottom {
  padding: 24px;
  color: inherit;
  background: rgba(13, 23, 24, 0.2);

  @media (max-width: 1199px) {
    padding: 16px 24px;
  }

  .authUser-content-bottom-title {
    display: flex;
    justify-content: space-between;

    >button {
      border: none;
      text-decoration: underline;
      background: none;
      cursor: pointer;
    }
  }
}

.flex-vertical {
  display: flex;
  align-items: center;
}

.authUser-content-bottom-body {
  margin-top: 16px;

  >ul {
    padding-inline: 0;
    list-style-type: none;

    >li.txnHistory-entry {
      display: flex;
      flex-wrap: nowrap;
      margin-block: 4px;

      >* {
        margin-inline-end: 4px;
      }
    }
  }

  p {
    margin-bottom: 0px;
  }
}

.connected-pop-over {
  .cross {
    right: 24px;
  }
}
