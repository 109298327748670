@use 'sass:map';
@import '../../../styles/mixins.scss';

.investWrapper {
  margin-top: 2rem;

  .expandSVG {
    margin: 0 1.5rem;

    @media (max-width: 899px) {
      margin: 0;
    }
  }

  .title {
    margin: 0.5rem 0;
    padding-left: 1.5rem;

    @media (max-width: 899px) {
      padding-left: 0;
    }
  }

  svg {
    @include themify() {
      color: themed('textSecondary');
    }
  }

  .Mui-expanded .invest-header {
    border-bottom: 1px solid;

    @media (max-width: 1199px) {
      border-bottom: none;
    }
  }

  .investDetails {
    padding-left: 0;
    padding-right: 0;
  }

  .mobileHR {
    display: none;

    @media (max-width: 899px) {
      position: absolute;
      left: -3%;
      border: 0;
      width: 100vw;
      top: 0;
      border-top: 1px solid;
      display: block;
    }
  }

  .MuiAccordion-root {
    box-shadow: none;
    padding: 0;
    margin: 0;
    background: transparent;
    color: inherit;

    & .MuiAccordionSummary-root {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    & .Mui-expanded {
      min-height: 0;
    }
  }

  .Mui-selected {
    color: white;

    @include themify() {
      background: themed('colorButton');
    }
  }

  .MuiTabs-indicator {
    display: none !important;
  }

  .tableHr {
    display: none;
  }

  @media (max-width: 1199px) {
    .wrapper {
      background-color: transparent;
      padding: 0;
      border: 0;
    }

    .tableHr {
      display: block;
      width: 100%;
      margin: 0;
      border: 0;
      border-top: 1px solid;
      margin: 1rem 0 0;
    }
  }
}

.sorting-icon {
  font-size: 9px;
  margin-left: 4px;
  display: inline-block;
  transform: translateY(-2px) scaleX(1.5) scaleY(0.8);
  transition: 0.5s;

  &.active {
    transition: 0.5s;
    transform: rotate(180deg) translateY(2px) scaleX(1.5) scaleY(0.8);
  }
}

.investContainer {
  padding: 32px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 1199px) {
    border-radius: 0px !important;
    padding: 12px 16px;
    gap: 16px;
  }

  .goTo {
    position: absolute;
    top: 33px;
    right: 42.5px;

    svg {
      width: 22.5px;
      height: 22.5px;
    }

    @media (max-width: 1199px) {
      top: 12px;
      right: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .title {
    @media (max-width: 1199px) {
      padding: 26px 16px 0px 16px !important;
    }
  }

  .search {
    .strategy-button-group {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
    .wrapper {
      margin: auto 0px 0px auto;
    }
  }

  .assets-button-group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
      overflow-x: auto;
      gap: 16px;
      position: relative;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.investTable {
  .tableHeaders {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    // left: -5px;
    @media (max-width: 1199px) {
      left: 0 !important;
    }

    .tableItems {
      cursor: pointer;

      & .subtitle-2:hover {
        @include themify() {
          color: themed('textPrimary');
        }
      }
    }

    .chainClass {
      text-transform: uppercase;

      @include themify() {
        color: themed('textSecondary');
      }

      margin: 0;
      font-weight: 500;
      font-size: 12px;
    }

    .capacity-group {
      flex-direction: row;

      @media (max-width: 1439px) {
        align-items: flex-start !important;
        flex-direction: column !important;
      }
    }

    .fontAvailable {
      color: map.get($text-line, 'positive');
      background: map.get($miscellaneous, 'dark-green');
      border-radius: 8px;
      padding: 8px 16px;
      text-align: center;
      display: inline-block;
      min-width: 57px;
    }

    .fontFull {
      color: map.get($text-line, 'grey');
      background: map.get($text-line, 'darkest-grey');
      border-radius: 8px;
      padding: 8px 16px;
      text-align: center;
      display: inline-block;
      min-width: 57px;
    }

    .action-group {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start !important;

      @media (max-width: 1439px) {
        flex-direction: column !important;
      }
    }
  }

  @media (max-width: 899px) {
    padding: 0;
  }
}

.MuiAccordion-root {
  background: transparent !important;
  box-shadow: none !important;
  transition: background 0.5s !important;
  border-radius: 8px !important;
  margin-top: 12px;

  .MuiButtonBase-root {
    height: 90px;
    border-radius: 8px !important;

    @media (max-width: 1439px) {
      height: max-content;
    }

    &.MuiAccordionSummary-root {
      // padding: 12px 16px !important;
      padding: 0;


      &.Mui-expanded:hover {
        border-radius: 8px 8px 0px 0px !important;
      }
    }
  }

  .MuiAccordionSummary-root .MuiGrid-root {
    // display: flex;
    align-items: center;
  }
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}

.MuiAccordionSummary-content {
  &.Mui-expanded {
    &.MuiAccordionSummary-contentGutters {
      margin: 12px 0px !important;
    }
  }
}

.dropdown-bar {
  height: 38px;
  padding: 0px 16px;
  margin: 0px;
  align-items: center;

  .view-details {
    cursor: pointer;
    text-decoration: underline;
  }
}

.line-graph-box {
  background: map.get($miscellaneous, 'dark-background') !important;
}

.view-more {
  display: flex;
  justify-content: center;
  align-items: center;
}
