@use 'sass:map';
@import '../../../../styles/mixins.scss';

.authNetwork-trigger {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-sizing: border-box;
    outline: 1px solid map.get($text-line, 'light-grey-blue');
  }
}

[aria-describedby='simple-popover']>.authNetwork-trigger {
  box-sizing: border-box;
  outline: 1px solid map.get($text-line, 'light-grey-blue');
}

.authNetwork-icon {
  margin-right: 8px;
}

.authNetwork-ticker {
  margin-right: 8px;
}

.authNetwork-down {
  position: relative;
  top: 2px;
}

.authNetwork-content {
  box-sizing: border-box;
  padding: 12px;
  border-radius: 8px;

  // width: 220px;
  .title {
    margin-bottom: 8px;
  }

  .authNetwork-list {
    padding: 8px 12px 8px 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &:hover {
      border-radius: 8px;
    }

    .circular {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-left: auto;
    }

    &.network-disabled {
      cursor: auto !important;
      pointer-events: none;
    }

    .label-soon {
      text-transform: none !important;
      border-radius: 6px;
      padding: 0px;

      &.btn.btn-sm::after {
        font-weight: 500;
        font-size: 11px;
        line-height: 11px;
        padding: 4px 6px;
      }

      &.btn.btn-sm::before {
        border-radius: 6px;
      }
    }
  }
}

.MuiPopover-paper {
  border-radius: 8px !important;
  margin-top: 6px;
  background: none;

  @media (max-width: 1199px) {
    margin-top: 4px;
  }
}