@import './colors.scss';

$themes: (
  light: (
    backgroundPrimary: $light-backgroundPrimary,
    backgroundSecondary: $light-backgroundSecondary,
    textPrimary: $light-textPrimary,
    textSecondary: $light-textSecondary,
    textThird: $light-textThird,
    button: $light-button,
    buttonHover: $light-buttonHover,
    buttonBorder: $light-buttonBorder,
    colorButton: $light-colorButton,
    colorButtonHover: $light-colorButtonHover,
    borderColor: $light-borderColor,
    itemColor: $light-itemColor,
    authColor: $light-authColor,
    colorPrimary: $light-colorPrimary,
    menuHighlightColor: $light-menuHighlightColor,
    menuColor: $light-menuColor,
    errorColor: $light-errorColor,
  ),
  dark: (
    backgroundPrimary: $dark-backgroundPrimary,
    backgroundSecondary: $dark-backgroundSecondary,
    textPrimary: $dark-textPrimary,
    textSecondary: $dark-textSecondary,
    textThird: $dark-textThird,
    button: $dark-button,
    buttonHover: $dark-buttonHover,
    buttonBorder: $dark-buttonBorder,
    colorButton: $dark-colorButton,
    colorButtonHover: $dark-colorButtonHover,
    borderColor: $dark-borderColor,
    itemColor: $dark-itemColor,
    authColor: $dark-authColor,
    colorPrimary: $dark-colorPrimary,
    menuHighlightColor: $dark-menuHighlightColor,
    menuColor: $dark-menuColor,
    errorColor: $dark-errorColor,
  ),
);

$text-line: (
  'light-white': $light-white,
  'light-grey': $light-grey,
  'light-grey-blue': $light-grey-blue,
  'grey': $grey,
  'dark-grey': $dark-grey,
  'darkest-grey': $darkest-grey,
  'light-black': $light-black,
  'negative': $negative,
  'positive': $positive,
  'black': $black,
  'white': $white,
);

$gradient: (
  'standard': $gradient-standard,
  'logo': $gradient-logo,
  'icon': $gradient-icon,
  'light': $gradient-light,
  'hover-dark': $gradient-hover-dark,
);

$warning: (
  'error': $error,
  'warning': $warning-error,
  'error-dark': $error-dark,
);

$miscellaneous: (
  'strategy-hover': $strategy-hover,
  'bright-blue': $bright-blue,
  'light-blue': $light-blue,
  'grey-blue': $grey-blue,
  'light-green': $light-green,
  'dark-green': $dark-green,
  'blue-green': $blue-green,
  'green-gradient-background': $green-gradient-background,
  'dark-transparent-background': $dark-transparent-background,
  'strategy-background': $strategy-background,
  'nav-bar-background': $nav-bar-background,
  'card-background': $card-background,
  'card-solid': $card-solid,
  'page-background': $page-background,
  'dark-background': $dark-background,
  'background-mask': $background-mask,
);

$modal: (
  'light-transparent': $modal-light-transparent,
  'dark-transparent': $modal-dark-transparent,
  'dark-background': $modal-dark-background,
  'background': $modal-background,
);

$effect-styles: (
  'dark': $shadow-dark,
  'light': $shadow-light,
  'modal': $modal-effect,
  'drop': $drop-shadow,
);

@mixin themify() {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
