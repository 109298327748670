@use 'sass:map';
@import '../../../../styles/mixins.scss';

.search {
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

  .wrapper {
    height: 32px;
    width: 400px;
    display: flex;
    align-items: center;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;

    @media (max-width: 1199px) {
      height: 40px;
      width: calc(100vw - 32px);
    }

    p {
      margin: 0;
    }

    &:focus-within {
      img {
        filter: brightness(0) invert(1);
      }
    }

    .tokenSearch {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-transform: uppercase;

      & > span {
        cursor: pointer;

        @include themify() {
          background-color: themed('backgroundSecondary');
        }

        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        border-radius: 4px;
        border: 1px solid;

        p {
          padding: 0.25rem 0.5rem;
          margin-right: 0.5rem;
          border-right: 1px solid;
        }

        span {
          padding-right: 0.5rem;
        }
      }
    }
  }

  .searchWrapper {
    padding: 0 16px;
    height: 44px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;

    @include themify() {
      color: themed('itemColor');
    }

    .close-icon {
      margin: '0.75rem';
      color: map.get($text-line, 'light-white');
    }

    svg {
      cursor: pointer;
    }
  }

  .MuiInputBase-root {
    width: 100%;
    font-family: 'Poppins';
  }

  input {
    padding: 3px 0px;

    &::placeholder {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: map.get($text-line, 'light-grey') !important;
      opacity: 1;

      @media (max-width: 1199px) {
        font-weight: 500;
      }
    }
  }

  .filterSearch {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    p {
      margin: 0;
      margin-left: 0.25rem;
      margin-right: 0.5rem;
      font-size: 12px;

      @include themify() {
        color: themed('textSecondary');
      }
    }

    span {
      font-size: 12px;

      @include themify() {
        color: themed('textPrimary');
      }

      padding: 0.15rem 0.33rem;
      border: 1px solid;
      border-radius: 4px;
      font-weight: 500;
      margin: 0 4px;
      cursor: pointer;

      &:hover,
      &.active {
        @include themify() {
          background: themed('buttonBorder');
        }

        border: 0;
        margin: 1px 5px;
        color: white;
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid;
    margin-top: 2rem;
  }

  .manageClass {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    cursor: pointer;

    svg {
      font-size: 22px;
      margin: 0 0.25rem;

      @include themify() {
        color: themed('textSecondary');
      }
    }

    p {
      text-transform: capitalize;
      margin: 0;

      @include themify() {
        color: themed('textPrimary');
      }
    }
  }

  .strategy-button-group {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 1199px) {
      gap: 8px;
    }

    > div {
      gap: 16px;
      position: relative;
      height: 32px;

      @media (max-width: 1199px) {
        gap: 12px;
        height: 32px;

        > div {
          padding: 4px 8px;
        }
      }
    }
  }
}
