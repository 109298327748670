@use 'sass:map';
@import '.,/../../../../../styles/mixins.scss';

.assetModal {
  background-color: map.get($miscellaneous, 'background-mask');
  z-index: 10000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  cursor: auto;
  align-items: center;
  justify-content: center;

  @media (max-width: 1199px) {
    // margin-bottom: 71px;
    // margin-top: 68px;
    // height: auto !important;

    p {
      margin: 0;
    }
  }

  .assetHeightContainer {
    & > hr {
      margin: 0;
    }

    @media (max-width: 1199px) {
      max-height: 100%;
      margin-top: 30px;
      margin-bottom: 16px;
    }
  }

  .contentContainer {
    @media (max-width: 1199px) {
      max-height: calc(100vh - 165px);
    }
  }

  & > div {
    box-sizing: border-box;
    padding: 32px 24px;
    box-shadow: map.get($effect-styles, 'modal');
    width: 580px;
    height: auto;
    margin: 0;
    max-width: 580px;
    min-height: 484px;
    text-align: left;
    border-radius: 8px;
    position: relative;
    overflow: visible;

    // &:before {
    //   position: absolute;
    //   content: '';
    //   height: calc(100% + 2px);
    //   width: calc(100% + 2px);
    //   z-index: -1;
    //   left: -1px;
    //   top: -1px;
    //   border-radius: 8px;

    //   @include themify() {
    //     background: themed('buttonBorder') !important;
    //   }

    //   @media (max-width: 1199px) {
    //     content: none;
    //   }
    // }

    .MuiPaper-root {
      &:before {
        content: none;
      }
    }

    @media (max-width: 1199px) {
      // @include themify() {
      //   background-color: themed('backgroundSecondary');
      // }
      padding: 0px 16px;
      overflow-y: none;
      overflow-x: clip;
      border-radius: 0;
      height: 100%;
      max-width: 100%;
      min-height: 100%;
      width: 100%;
      margin: 0;
      outline: none;
    }
  }

  h4 {
    &.title {
      text-transform: uppercase;
      height: 30px;

      @include themify() {
        color: themed('textPrimary');
      }

      font-size: 24px;
      font-weight: 500;
      // padding: 1rem 0;
      margin: 0;
      text-align: center;

      @media (max-width: 1199px) {
        margin-left: 60px;
        text-align: left;
        padding: 0.5rem 0;

        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;

        /*         @include themify() {
          background-color: themed('backgroundPrimary');
        } */
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid;

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .assetContainer {
    padding: 16px 0px 0px;

    @media (max-width: 1199px) {
      max-height: calc(100vh - 250px);
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: 16px;
    }

    &:has(.withdrawal-slider) {
      @media (max-width: 1199px) {
        max-height: calc(100vh - 184px);
      }
    }

    & > div {
      margin-bottom: 16px;
      border-radius: 7px;
      padding: 12px 24px;
      display: flex;
    }
  }

  .popover-trigger {
    width: auto;

    @media (max-width: 1199px) {
      position: fixed;
      right: 20px;
      top: 36px;
    }
  }
}
