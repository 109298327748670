.back-test-anlysis {
    padding: 32px;

    .headline-4 {
        margin-bottom: 10px;
    }

    .graph-title {
        position: relative;
        margin-bottom: 20px;
    }

    .float-right {
        position: absolute;
        right: 32px;
        top: 0px;
    }

    .span-1 {
        margin-left: 8px;
        margin-right: 8px;
    }
}